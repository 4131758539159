import React, { useContext, useEffect, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';

import NavigationContext from "./NavigationContext";
import Form from "./forms/";
import AppLink from "./AppLink";

export default function LoginPage() {

    const { setPageState, navigateFn } = useContext(NavigationContext);
    useEffect(() => {
        setPageState({title: 'Log In', pageWidth: 'mini'});
    }, []);

    const [ googleSigninError, setGoogleSigninError ] = useState(null);
    const onGoogleSigninSuccess = r => {
        fetch('/api/v3/session/google-signin', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(r),
        }).then(r => {
            if (r.ok) {
                navigateFn('/')
            } else {
                setGoogleSigninError('Unable to sign in with Google.')
            }
        })
    }
    const onGoogleSigninError = r => {
        console.error(r);
    }

    const [googleSigninVisibility, setGoogleSigninVisibility] = useState('hidden')
    useEffect(() => {
        setTimeout(_ => {
            setGoogleSigninVisibility('visible')
        }, 1500);
    }, []);

    return <div>
        <Form className="page-content login-form"
            url="/api/v3/session" onSuccess={r => navigateFn('/')} 
            defaultErrorMsg='Unsuccessful login attempt. Try again, or click "Forgot Password" to reset your password.'>
        <Form.Field labelText="Email Address" fieldName="emailAddress" type="email" required={true} />
        <Form.Field labelText="Password" fieldName="password" type="password" required={true} />
        <Form.Submit buttonText="Log In">
            <AppLink className="forgot-password-link" href="/forgot-password">Forgot Password?</AppLink>
        </Form.Submit>
    </Form>

    <hr />

    <div style={{'visibility': googleSigninVisibility }}>
        <GoogleLogin theme="filled_blue" onSuccess={onGoogleSigninSuccess} onError={onGoogleSigninError} click_listener={e => setGoogleSigninError(null)} />
    </div>
        {googleSigninError &&
            <div className="alert alert-danger mt-4" role="alert">
                {googleSigninError}
            </div>
        }
    </div>;
}
