import { useContext, useEffect, useState } from "react";
import Form from "./forms/";
import NavigationContext from "./NavigationContext";

export default function ForgotPasswordPage() {
    const [ isSent, setIsSent ] = useState(false);
    const { setPageState } = useContext(NavigationContext);
    useEffect(() => {
        setPageState({title: 'Reset Your Password', pageWidth: 'mini'});
    });

    return <div className="page-content page-mini">
        <p className="alert alert-info">
            {isSent ?
                'Check your email for a link to reset your password.' :
                'Enter your email below, and we will send you a link to reset your password.'
            }
        </p>
        <Form url="/api/v3/passwordResetRequest" onSuccess={r => setIsSent(true)} isDisabled={isSent}
                defaultErrorMsg='Unable to change your password. Try again, or email info@2040energy.com for help.'>
            <Form.Field labelText="Email Address" fieldName="emailAddress" type="email" required={true} />
            <Form.Submit buttonText="Reset Password" />
        </Form>
    </div>
}