import { useContext } from "react";
import NavigationContext from "./NavigationContext";

import React, { useEffect, useState } from "react";
import ReservationList from "./ReservationList";
import Spinner from "./Spinner";

export default function AccountSummaryPage() {
    const [data, setData] = useState();
    const [isError, setIsError] = useState(false);

    const { setPageState, navigateFn } = useContext(NavigationContext);
    setPageState({title: 'My Account Home', pageWidth: 'med', isLoggedIn: true});

    useEffect(() => {
        fetch('/api/v3/accountSummary').then(r => {
            if (r.ok) {
                r.json().then(d => setData(d));
            } else if (r.status === 401 || r.status === 403) {
                navigateFn(`/login`)
            } else {
                setIsError(true);
            }
        })
    }, []);

    let child;
    if (isError) {
        child = <div className="alert alert-danger" role="alert">
            We are unable to load your My2040 account. Please try again.
        </div>
    } else if (!data) {
        child = <div className="text-center"><Spinner /></div>
    } else {
        child = <div>
            <ReservationList reservations={data.reservations} emailAddress={data.emailAddress} />
        </div>
    }

    return <div className="page-content">
        {child}
    </div>

}