import React from "react";
import ReservationListItem from "./ReservationListItem";

export default function ReservationList({ emailAddress, reservations }) {

    return <React.Fragment>
        {/* <h3>{reservations.length > 1 ? 'Reservations' : 'Reservation'}:</h3> */}
        <div className="reservation-list">
            {reservations.map(r => <ReservationListItem key={r.reservationNumber} emailAddress={emailAddress} {...r} />)}
        </div>
    </React.Fragment>
}