import { useContext, useEffect, useState } from "react";
import Spinner from "./Spinner";
import AppLink from "./AppLink";
import NavigationContext from "./NavigationContext";

export default function LogoutPage() {
    let [isComplete, setIsComplete] = useState(false);
    useEffect(() => {
        fetch('/api/v3/session', {
            method: 'DELETE'
        }).then(r => {
            setIsComplete(true);
        })
    }, []);
    

    const { setPageState } = useContext(NavigationContext);

    if (isComplete) {
        setPageState({title: 'Logged Out', pageWidth: 'mini', isLoggedIn: false});
        return  <p className="text-center">You are logged out. <AppLink href="/login">Click here</AppLink> to log back in.</p>
    } else {
        setPageState({title: 'Logging Out', pageWidth: 'mini', isLoggedIn: true});
        return <div className="text-center"><Spinner /></div>
    }
}