import React, { useState } from "react";

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export default function ReservationListItem({ id, emailAddress, createdDate, zipCode, sizeId, comments, isCancelled, isPaid, isUsingOldPricing, reservationNumber }) {
    const sizeText = sizeId ? ['Medium', 'Large', 'Extra Large'][sizeId - 1] : 'TBD';
    const paymentLinkRoot = window.location.hostname === 'localhost' ?
        'https://buy.stripe.com/test_aEU5nb9nX4Gdba8144' :
        'https://buy.stripe.com/00gbJ098J4vV55C4gg';
    const paymentLink = paymentLinkRoot + `?prefilled_email=${encodeURIComponent(emailAddress)}&client_reference_id=${encodeURIComponent(id)}`

    const [areTermsAccepted, setAreTermsAccepted] = useState(false);


    function handlePaymentSubmit(evt) {
        evt.preventDefault();
        if (!areTermsAccepted) {
            window.alert("You must accept the pre-order agreement");
            return;
        }

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                window.location.reload();
            }
        });

        window.open(paymentLink, '_blank');
    }

    const createdDateObj = new Date(createdDate + 'Z');
    const paymentDeadlineDateObj = addDays(createdDateObj, 4);
    const isExpired = new Date() > paymentDeadlineDateObj;

    return <div className="reservation-list-item">
        <h4>Reservation #{reservationNumber}</h4>
        <div className="row">
            <div className="col-md-6">
                <div><strong>Date:</strong> {createdDateObj.toLocaleDateString()}</div>
                {/* <div><strong>Deposit:</strong> {isPaid ? 'Paid' : 'Unpaid'}</div> */}
                <div><strong>Zip Code:</strong> {zipCode}</div>
                <div><strong>Size:</strong> {sizeText}</div>
                {comments && <React.Fragment>
                    <div><strong>Comments:</strong></div>
                    <div><em>{comments}</em></div>
                </React.Fragment>}
            </div>
            <div className="col-md-6">
                {isPaid ?
                    <div className="alert alert-success"><strong>✓</strong>  Your reservation deposit is paid.</div> :
                isExpired ?
                    <div className="alert alert-danger">
                        <p>Your reservation has expired since we did not receive a deposit payment.</p>
                        <p>Contact <a href="mailto:info@2040energy.com">info@2040energy.com</a> with questions, or if you would like to reactivate your reservation.</p>
                    </div> :
                    <form onSubmit={handlePaymentSubmit}>
                        <div className="alert alert-danger">
                            Your reservation deposit has not yet been paid.
                            You must complete payment by <strong>{paymentDeadlineDateObj.toLocaleDateString()}</strong> or your reservation will be cancelled.
                        </div>
                        <div className="reservation-list-item-agreement">
                            <label>
                                <input type="checkbox" required={true} onChange={e => setAreTermsAccepted(!areTermsAccepted)} checked={areTermsAccepted} />
                                I agree to the terms & conditions of the <a target="_blank" rel="noreferrer" href="https://www.2040energy.com/preorder-agreement/">
                                    2040&nbsp;Energy&nbsp;Pre&#8209;Order&nbsp;Agreement
                                </a>
                            </label>
                        </div>
                        <div>
                            <button className="btn btn-primary" type="submit">Click Here</button><span> to pay your deposit.</span>
                        </div>
                    </form>
                }
            </div>
        </div>
    </div>;
}