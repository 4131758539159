import { useState } from "react";
import FormContext from "./FormContext";

export default function Form({ url, onSuccess, onValidate, defaultErrorMsg, isDisabled, children, ...attrs }) {
    const [isPosting, setIsPosting] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
  
    var handleSubmit = e => {
        e.preventDefault();
        setErrorMsg(null);

        const fieldData = {};
        const formData = new FormData(e.currentTarget)
        formData.forEach((value, property) => fieldData[property] = value);

        if (onValidate) {
            const validateError = onValidate(fieldData);
            if (validateError) {
                setErrorMsg(validateError);
                return;
            }
        }

        setIsPosting(true);
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(fieldData),
        }).then(r => {
            setIsPosting(false);
            if (r.ok) {
                onSuccess(r);
            } else {
                setErrorMsg(defaultErrorMsg)
            }
        })
    };

    return <form onSubmit={handleSubmit} {...attrs}>
        <FormContext.Provider value={{isPosting, isDisabled, errorMsg}}>
            {children}
        </FormContext.Provider>
    </form>;
}