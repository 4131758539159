import { useEffect, useState } from "react";

import { Container, Nav, Navbar } from 'react-bootstrap';
import AccountSummaryPage from './AccountSummaryPage';
import AccountConfirmationPage from "./AccountConfirmationPage";
import PasswordResetPage from "./PasswordResetPage";
import LoginPage from "./LoginPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import LogoutPage from "./LogoutPage";
import NavigationContext from "./NavigationContext";
import AppLink from "./AppLink";

function App() {

  let [isNavExpanded, setIsNavExpanded] = useState(false);
  let [urlPath, setUrlPath] = useState(window.location.pathname);
  let [pageTitle, setPageTitle] = useState('');
  let [pageWidth, setPageWidth] = useState('mid');
  let [isLoggedIn, setIsLoggedIn] = useState(false);

  let setPageState = ({title, pageWidth, isLoggedIn}) => {
    setPageTitle(title);
    setPageWidth(pageWidth);
    setIsLoggedIn(!!isLoggedIn);
  }

  document.title = `My2040 - ${pageTitle}`;
  const navigateFn = pathAndQuery => {
    setIsNavExpanded(false);
    window.history.pushState({}, '', pathAndQuery);
    setUrlPath(window.location.pathname);
  }

  useEffect(() => {
    window.addEventListener("popstate", e => {
      setUrlPath(window.location.pathname)
    });
    onpopstate = (event) => { };
  }, []);

  let pageContent;
  if (urlPath === '/account-confirmation') {
    pageContent = <AccountConfirmationPage />
  } else if (urlPath === '/reset-password') {
    pageContent = <PasswordResetPage />
  } else if (urlPath === '/login') {
    pageContent = <LoginPage />
  } else if (urlPath === '/forgot-password') {
    pageContent = <ForgotPasswordPage />
  } else if (urlPath === '/logout') {
    pageContent = <LogoutPage />
  } else {
    pageContent = <AccountSummaryPage />
    if (urlPath !== '/') {
      window.history.replaceState(null, '', '/');
    }
  }

  return (
    <div className="App" onClick={e => setIsNavExpanded(false)}>
      <NavigationContext.Provider value={{ setPageState, navigateFn }}>
        <Navbar className="navbar navbar-dark bg-dark" fixed="top"
          expand="false" expanded={isNavExpanded}
          onToggle={exp => setIsNavExpanded(exp)}
          onClick={e => e.stopPropagation()} >
          <div className="container">
            <AppLink className="navbar-brand" href="/">
              My<span className="numbers">2040</span>
            </AppLink>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <a className="nav-link" href="https://www.2040energy.com">Back to www.2040energy.com</a>
                {isLoggedIn && <AppLink className="nav-link" href="/logout">Log Out</AppLink>}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <div className={`container ${pageTitle ? 'visible' : 'invisible'}`}>
          <div className={`page page-${pageWidth}`}>
            <h1>{pageTitle}</h1>
            {pageContent}
          </div>
        </div>
      </NavigationContext.Provider>
    </div >
  );
}

export default App;
