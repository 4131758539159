import FormContext from "./FormContext";
import Spinner from "../Spinner";
import React, { useContext } from "react";

export default function FormSubmit({ buttonText, children }) {
    const { isPosting, isDisabled, errorMsg } = useContext(FormContext);
    return <React.Fragment>
        <div className="form-submit-container">
            <button className="btn btn-primary" disabled={isPosting || isDisabled} > 
                {isPosting ? <Spinner /> : buttonText}
            </button>
            {children}
        </div>
        {errorMsg &&
            <div className="alert alert-danger" role="alert">
                {errorMsg}
            </div>
        }

    </React.Fragment>

}