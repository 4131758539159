import { useContext, useEffect } from "react";
import Form from "./forms/";
import NavigationContext from "./NavigationContext";

export default function PasswordResetPage() {

    const { setPageState, navigateFn } = useContext(NavigationContext);
    useEffect(() => {
        setPageState({title: 'Reset Your Password', pageWidth: 'mini'});
    });

    const urlParams = new URLSearchParams(window.location.search);
    const emailAddress = urlParams.get('email');
    const token = urlParams.get('token');

    var onValidateFn = d => {
        if (d.newPassword !== d.newPassword2) {
            return 'The passwords entered do not match.';
        }
    }

    return <div className="page-content page-mini">
        <p className="alert alert-info">Set your new password below.</p>
        <Form url="/api/v3/passwordResetVerification" onSuccess={r => navigateFn('/')}
                onValidate={onValidateFn}
                defaultErrorMsg='Unable to change your password. Try again, or email info@2040energy.com for help.'>
            <Form.Field labelText="" fieldName="token" type="hidden" value={token} />
            <Form.Field labelText="" fieldName="emailAddress" type="hidden" value={emailAddress} />
            <Form.Field labelText="Password" fieldName="newPassword" type="password" required={true} />
            <Form.Field labelText="Confirm Password" fieldName="newPassword2" type="password" required={true} />
            <Form.Submit buttonText="Set Password" />
        </Form>
    </div>
}