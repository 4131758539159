import { useContext, useEffect, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';

import Form from "./forms/";
import NavigationContext from "./NavigationContext";

export default function AccountConfirmationPage() {

    const { setPageState, navigateFn } = useContext(NavigationContext);
    useEffect(() => {
        setPageState({ title: 'Confirm Your Account', pageWidth: 'mini' });
    });

    const urlParams = new URLSearchParams(window.location.search);
    const emailAddress = urlParams.get('email');
    const token = urlParams.get('token');

    var onValidateFn = d => {
        if (d.password !== d.password2) {
            return 'The passwords entered do not match.';
        }
    }

    const [ googleSigninError, setGoogleSigninError ] = useState(null);
    const onGoogleSigninSuccess = r => {
        fetch('/api/v3/session/google-signin', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(r),
        }).then(r => {
            if (r.ok) {
                navigateFn('/')
            } else {
                setGoogleSigninError('Unable to sign in with Google.')
            }
        })
    }
    const onGoogleSigninError = r => {
        console.error(r);
    }
    const [googleSigninVisibility, setGoogleSigninVisibility] = useState('hidden')
    useEffect(() => {
        setTimeout(_ => {
            setGoogleSigninVisibility('visible')
        }, 1500);
    }, []);

    return <div className="page-content page-mini">
        <p className="alert alert-info">To finish setting up your account, set a password below or continue with Google.</p>
        <Form url="/api/v3/accountConfirmation" onSuccess={r => navigateFn('/')}
            onValidate={onValidateFn}
            defaultErrorMsg='Unable to verify your account. Try again, or email info@2040energy.com for help.'>
            <Form.Field labelText="" fieldName="token" type="hidden" value={token} />
            <Form.Field labelText="" fieldName="emailAddress" type="hidden" value={emailAddress} />
            <Form.Field labelText="Password" fieldName="password" type="password" required={true} />
            <Form.Field labelText="Confirm Password" fieldName="password2" type="password" required={true} />
            <Form.Submit buttonText="Set Password" />
        </Form>

        <hr />

        <div style={{'visibility': googleSigninVisibility }}>
            <GoogleLogin theme="filled_blue" text="continue_with" onSuccess={onGoogleSigninSuccess} onError={onGoogleSigninError} click_listener={e => setGoogleSigninError(null)} />
        </div>
        {googleSigninError &&
            <div className="alert alert-danger mt-4" role="alert">
                {googleSigninError}
            </div>
        }
    </div>;
}
